import React from 'react';

function AuthReceiver() {

    return (
        <React.Fragment>
            <div className="grid m-auto">
                <div className="col-9">
                    Return from login.  Seems like a pointless page now.
                </div>
            </div>
        </React.Fragment>
    );
}

export default AuthReceiver;
