import { Menubar } from 'primereact/menubar';
import {useFusionAuth} from "@fusionauth/react-sdk";
import React from "react";

function NavBar() {

    const {isLoading, isAuthenticated, user, login, logout} = useFusionAuth();

    if (isLoading) {
        return null;
    }

    const loggedOutItems = [
        {
            label: 'Appliance Monitor',
            icon: 'fab fa-cloudversify',
            url: '/#/'
        },
        {
            label: 'Login',
            command: () => login(),
        }
    ];

    const loggedInItems = [
        {
            label: 'Appliance Monitor',
            icon: 'fab fa-cloudversify',
            url: '/#/'
        },
        {
            label: 'Settings',
            url: '/#/settings'
        },
        {
            label: 'Logout',
            command: () => logout(),
        }
    ];

    return (
        <div className="card">
            {
                isAuthenticated ? (
                    <Menubar model={loggedInItems} />
                ) : (
                    <Menubar model={loggedOutItems} />
                )
            }
        </div>
    );
}

export default NavBar;
