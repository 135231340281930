import React from 'react';

//  React router
import {Route, Routes} from "react-router-dom";

import {useFusionAuth} from "@fusionauth/react-sdk";

//  Primereact UI
//  (Full list of themes here: https://primereact.org/theming/)
import "primeflex/primeflex.css";                                       //flex
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";   //theme
import "primereact/resources/primereact.min.css";                       //core css
import "primeicons/primeicons.css";                                     //icons

//  Global Styles
import './App.css';

//  App components
import NavBar from './components/common/NavBar';
import ApplianceMonitorHome from './components/ApplianceMonitorHome';
import NotFound from './components/common/NotFound';
import SettingsHome from "./components/settings/SettingsHome";
import AuthReceiver from "./components/common/AuthReceiver";

const App = () => {

    const {isLoading} = useFusionAuth();

    if (isLoading) {
        return null;
    }

    //  Return the navbar and our target route
    return (
        <React.Fragment>
            <NavBar/>
            <Routes>
                <Route path="/" element={<ApplianceMonitorHome/>}/>
                <Route path="/settings" element={<SettingsHome/>}/>
                <Route path="/auth" element={<AuthReceiver/>}/>
                <Route path="*" element={<NotFound/>} />
            </Routes>
        </React.Fragment>
    );
}

export default App;