//  React
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

//  Redux
import { Provider } from 'react-redux';
import { store } from './store';

//  App
import './index.css';
import App from './App';

//  React router
import {HashRouter} from "react-router-dom";

//  FusionAuth
import { FusionAuthProvider } from '@fusionauth/react-sdk';
import {config} from "./config";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <HashRouter>
                <FusionAuthProvider {...config}>
                    <App />
                </FusionAuthProvider>
            </HashRouter>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
