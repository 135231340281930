import React from 'react';
import {useFusionAuth} from "@fusionauth/react-sdk";

function SettingsHome() {

    const {isLoading, isAuthenticated, user, login, logout} = useFusionAuth();

    if (isLoading) {
        return null;
    }

    console.log(user)

    return (
        <React.Fragment>

            {
                isAuthenticated ? (
                    <div className="grid m-auto">
                        <div className="col-9">
                            This is the settings for {user.name}
                        </div>
                    </div>
                ) : (
                    <div className="grid m-auto">
                        <div className="col-9">
                            Please login
                        </div>
                    </div>
                )
            }

        </React.Fragment>
    );
}

export default SettingsHome;