import React from 'react';

function NotFound() {
    return (
        <React.Fragment>
            <div className="grid m-auto">
                <div className="col-9">
                    Sorry -- I couldn't find that page
                </div>
            </div>
        </React.Fragment>
    );
}

export default NotFound;
